import { useStaticQuery, graphql } from "gatsby";

export default function useChemicalIncidents() {
  const data = useStaticQuery(graphql`
    query Data {
      allChemicalJson {
        edges {
          node {
            incident_code
            incident_date
            id
            latitude
            location_ar
            location_en
            longitude
            summary_ar
            summary_en
            title_ar
            title_en
            weapons
          }
        }
      }
    }
  `);
  return { data };
}
