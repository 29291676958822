import React, { useContext, useState, useEffect } from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import { rhythm } from "utils/typography";
import Seo from "components/seo";
import Pagination from "rc-pagination";
import LocalizedLink from "components/localizedLink";
import IncidentList from "components/datasets/incident-list";
import { ThemeContext } from "context/theme-context";
import { LocaleContext } from "context/locale-context";
import Layout from "components/layout";
import PostHeader from "components/PostHeader";
import SyriaMap from "components/database/map";
import PropTypes from "prop-types";
import { mq } from "utils/helper";
import useChemicalIncidents from "hooks/useChemicalIncidents";
import Timeline from "components/charts/timeline";
import { FaAngleLeft, FaCircle } from "react-icons/fa";
import { slice } from "ramda";
import useWindowSize from "hooks/useWindowSize";
import useTranslations from 'hooks/useTranslations';

import "../../style/pagination.css";

const PER_PAGE = 20;

const container = css({
  display: "grid",
  gridTemplateColumns: "repeat(2, minmax(20px, 1fr))",
  gridColumnGap: rhythm(1),
  maxWidth: "1200px",
  margin: "auto",
  [mq[0]]: {
    display: "block",
  },
});

function GoBack() {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <LocalizedLink
        to={"datasets/chemical-weapons"}
        css={{
          position: "relative",
          marginLeft: rhythm(0.5),
          [mq[1]]: {
            marginLeft: rhythm(1),
          },
        }}
      >
        <span
          css={{
            paddingRight: rhythm(0.4),
            position: "absolute",
            top: rhythm(-0.1),
            left: rhythm(-0.8),
          }}
        >
          <FaAngleLeft />
        </span>
        <span
          css={{
            fontWeight: "bold",
          }}
        >
          {tr("Back to Key Findings")}
        </span>
      </LocalizedLink>
      <h4
        css={{
          marginTop: rhythm(1),
          [mq[1]]: {
            marginLeft: rhythm(0.5),
          },
        }}
      >
        <FaCircle
          css={{
            marginLeft: isRTL ? "0.6rem" : 0,
            marginRight: isRTL ? 0 : "0.6rem",
            color: colors.primary,
            fontSize: ".8em",
          }}
        />
        {tr("Incidents Data")}
      </h4>
    </div>
  );
}

function ChemicalCollection({ data: collection, location }) {
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  const { data: nodes } = useChemicalIncidents();
  const [currentPage, setCurrentPage] = useState(1);
  const incidents = nodes.allChemicalJson.edges.map((n) => n.node);

  const tr = useTranslations();
  const [windowWidthSize] = useWindowSize();
  const [hoveredUnit, setHoveredUnit] = useState("IIIII");

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
  }, [windowWidthSize]);

  return (
    <Layout>
      <PostHeader post={collection.mdx} location={location} />
      <Seo metadata={{ title: tr("Chemical Weapons Database") }} />
      <div
        css={{
          backgroundColor: colors.bg,
          paddingTop: rhythm(2.5),
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <div
          css={{
            maxWidth: "1200px",
            margin: "auto",
          }}
        >
          <GoBack />

          <div
            css={{
              margin: "0 auto",
              marginTop: rhythm(1),
              marginBottom: rhythm(2),
              [mq[1]]: {
                margin: rhythm(1),
              },
            }}
          >
            <Timeline
              lang={locale}
              incidents={incidents}
              width={isMobile ? 380 : 1200}
              height={100}
              isLoading={false}
            />
          </div>

          <div css={container}>
            <div>
              {slice(
                PER_PAGE * (currentPage - 1),
                PER_PAGE * (currentPage - 1) + PER_PAGE,
                incidents
              ).map((incident) => (
                <IncidentList
                  path={`/details/${incident.incident_code}`}
                  incident={incident}
                  key={incident.incident_code}
                  setHoveredUnit={setHoveredUnit}
                />
              ))}
            </div>

            <div
              css={{
                position: "sticky",
                top: "12vh",
                height: "100vh",
                boxSizing: "border-box",
              }}
            >
              <SyriaMap
                units={incidents}
                hoveredUnitID={hoveredUnit}
                isLoading={false}
              />
            </div>
          </div>
          <Pagination
            pageSize={PER_PAGE}
            current={currentPage}
            total={incidents.length}
            onChange={(current) => setCurrentPage(current)}
          />
        </div>
      </div>
    </Layout>
  );
}

ChemicalCollection.propTypes = {
  data: PropTypes.object,
};

export default ChemicalCollection;

export const pageQuery = graphql`
  query CollectionPageB($locale: String!) {
    mdx(
      fileAbsolutePath: { regex: "/content/datasets/" }
      fields: { locale: { eq: $locale } }
      frontmatter: { parent: { eq: "chemical" } }
    ) {
      id
      frontmatter {
        parent
        title
        level
        date
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        desc
      }
    }
  }
`;
