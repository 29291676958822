import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Map, TileLayer, Popup, Circle, Marker } from "react-leaflet";
import L from "leaflet";
import "style/leaflet.css";
import { LocaleContext } from "context/locale-context";
import LocalizedLink from "components/localizedLink";
import { isNull } from "utils/helper";

function SyriaMap({ units, hoveredUnitID, isLoading }) {
  const locale = useContext(LocaleContext);
  const isAra = locale === "ar";
  const default_viewport = {
    lat: 34.7,
    lng: 37.9,
    zoom: 7,
  };

  // const MarkerRadius = (unit) => (hoveredUnitID === unit.id ? 10000 : 0);
  // const MarkerColor = (unit) =>
  //   hoveredUnitID === unit.id ? "#008080" : "#E95351";
  const position = [default_viewport.lat, default_viewport.lng];

  if (typeof window !== "undefined") {
    return (
      <>
        {isLoading ? (
          <div>Loading</div>
        ) : (
          <Map
            center={position}
            zoom={default_viewport.zoom}
            className="leaflet-map"
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {isNull(units) ||
              units.map((unit) => {
                return (
                  <Marker
                    key={unit.id}
                    position={[unit.latitude, unit.longitude]}
                    riseOnHover={true}
                    icon={L.icon({
                      iconUrl: "/assets/SA.png",
                      iconAnchor: [12, 40],
                    })}
                    opacity="0"
                  >
                    <Popup>
                      <span>
                        <LocalizedLink
                          to={`datasets/incident/details/${unit.incident_code}`}
                          state={{ incident: unit }}
                        >
                          {isAra ? unit.title_ar : unit.title_en}
                        </LocalizedLink>
                      </span>
                    </Popup>
                    <Circle
                      center={{ lat: unit.latitude, lng: unit.longitude }}
                      fillColor="#b33a00"
                      radius={5000}
                      fill={true}
                      stroke={false}
                      opacity={0}
                      fillOpacity={0.7}
                    />
                  </Marker>
                );
              })}
          </Map>
        )}
      </>
    );
  }
  return null;
}

SyriaMap.propTypes = {
  units: PropTypes.array,
  isLoading: PropTypes.bool,
  hoveredUnitID: PropTypes.string,
};

export default SyriaMap;
